.donations {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  h3 {
    color: #111;
    font-family: 'Montserrat', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 32px;

    @media (max-width: 1439px) {
      font-size: 48px;
      line-height: 62px;
      margin-top: 32px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
    }

  }

  .donateList {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    height: 915px;
    overflow-y: auto;

    @media (max-width: 767px) {
      height: 1115px;
    }

    .noDonations {
      font-size: 18px;
    }
  }

  .showMore {
    display: flex;
    justify-content: center;
    padding-top: 32px;

    button {
      background-color: inherit;
      outline: none;
      border: 1px solid #F73232;
      border-radius: 4px;
      padding: 14px 32px;
      font-size: 16px;
      color: #F73232;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #F73232;
        color: #ffffff;
      }
    }
  }
}