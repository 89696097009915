.Question {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 16px 0 32px;
    color: #111;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    cursor: pointer;

    .Arrow {
        min-width: 25px;
        min-height: 30px;

        path {
            fill: #FF2A23;
        }

        &Open {
            transform: rotate(180deg);

            path {
                fill: #111;
            }
        }
    }

    p {
        margin: 0;
    }
  }
  
  .Answer {
    max-height: 0;
    overflow: hidden;
    color: #8C93A6;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    transition: all 0.7s;
  
    &.Open {
      max-height: 280px;
      margin-bottom: 40px;

      @media (max-width: 1439px) {
        max-height: 355px;
      }
    }
}

@media (max-width: 767px) {
    .Question {
        font-size: 18px;
        line-height: 20px;
    }

    .Answer {
        font-size: 14px;
        line-height: 18px;
    }
}