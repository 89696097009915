.Page {
    background-color: #fff;
}

.OpenSection {
    width: 100%;
    height: 904px;
    background-color: #F1EEFD;
    background-image: url('../new-images/donation/background-donation.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 120px;
}

.Grid {
    display: grid;
    grid-template-columns: 0.439fr 0.571fr;
    gap: 164px;
}

.FirstGrid {
    height: 2850px;
}

.H2 {
    color: #111;
    font-family: 'Montserrat', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
}

.Info {
    margin-top: 344px;
}

.H3 {
    color: #111;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.Cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    margin: 80px 0 100px;
}

.Card {
    position: relative;
    width: 220px;
    height: 236px;
    background-color: #F5F6FD;
    border-radius: 20px;
    background-position: 0 -20px;
    padding: 106px 30px 30px;

    img {
        width: 100%;
        position: absolute;
        top: -70px;
        left: -15px;
    }

    img:nth-child(2) {
        display: none;
    }

    p {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.Questions {
    margin-bottom: 60px;
    h2 {
        margin-bottom: 60px;
    }
}

@media (max-width: 1919px) {
    .OpenSection {
        height: 800px;
        h2{
            font-size: 48px;
        }
    
        h3 {
            font-size: 24px;
        }
    }

    .Grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 34px;
    }

    .FirstGrid {
        height: 3070px;
    }

    .Info {
        margin-top: 278px;
    }

    .Card  {
        width: 210px;
        padding: 106px 27px 27px;
    }
}

@media (max-width: 1439px) {
    .OpenSection {
        height: 700px;
        background-size: auto auto;
        background-position: -300px;
        margin-bottom: 70px;

        h2 {
            margin-bottom: 0;
        }

        h3 {
            font-size: 26px;
            margin-top: 0;
        }
    }

    .H2 {
        font-size: 48px;
        line-height: 62px;
    }

    .Info {
        margin-top: 185px;
    }

    .FirstGrid {
        height: 1660px;
    }

    .Cards {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 67px;
        margin: 80px 0 100px;

        .Card {
            width: 349px;
            height: 165px;
            padding: 80px 37px 30px;

            img:nth-child(1) {
                display: none;
            }

            img:nth-child(2) {
                display: block;
                top: -70px;
                left: -15px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .OpenSection {
        background-image: none;
        background-color: inherit;
        height: auto;
    }

    .Info {
        margin-top: 64px;
    }

    .Grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 34px;
    }

    .FirstGrid {
        height: auto;
    }

    .H2 {
        br {
            display: none;
        }
    }

    .Cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 32px;
    }

    .Questions {
        margin-top: 70px;
    }
}

@media (max-width: 767px) {
    .OpenSection {
        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .H2 {
        font-size: 24px;
        line-height: 30px;

        br {
            display: none;
        }
    }

    .Cards {
        .Card {
            width: 312px;
            padding: 80px 28px 30px;
        }
    }
}

.mobileButton {
    display: none;
  
    div {
      width: 255px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FF2A23;
      border-radius: 73px;
      padding: 8px 24px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
    }
  
    @media (max-width: 1199px) {
      display: flex;
      justify-content: center;
    }
  
    @media (max-width: 767px) {
      div {
        width: 100%;
        height: 60px;
      }
    }
  }